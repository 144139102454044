<script>
/***
 Name: create/create
 Code: sa0ChunLuyu
 Time: 2021/11/17 09:42
 Remark: 创建
 */
import Numberrulelistopen from '../../../../../components/drawer/numberrulelist-openYP/numberrulelistYP.vue';//w选择编号规则
import BeforeC from '../before/item.vue'

export default {
  props: ['reloadList'],
  components: {BeforeC, Numberrulelistopen},
  data() {
    return {
      open_num_type: '1',// W选择编号规则弹窗变量
      Numberrulelist: false,// W选择编号规则弹窗变量
      name: '',
      group_id: 0,
      group_number: '',
      before_show: false,
      visible: false,
      get_number: '',
      user_list: [],
      save_list: [],
      yp_list: [],
      type_list: [],
      num_type: 1,
      link_draft: 0,
      create_data: {
        id: 0,
        type: 0, // 0 - 新建 1 - 修改
        spec_type: '',
        spec_types: [],
        name: '',
        choose: false,
        in_number: '',
        out_number: '',
        check_item: '',
        check_func: '',
        check_per: '',
        get_per: '',
        pick_per: '',
        pick_time: '',
        create_time: '',
        update_time: '',
        is_del: 2,
        group_id: '',
        check_items: [],
        check_funcs: [],
        check_pers: [],
        pick_pers: [],
        get_pers: [],
        save_id: undefined,
        jing_dot: '',
        wei_dot: '',
        pick_space: '',
        link_draft: 0,
        new_count: 1,
        status: 0,
      }
    }
  },
  watch: {
    $route: {
      handler() {
        this.mountedDo()
      },
      deep: true
    },
  },
  mounted() {
    this.mountedDo();
  },
  methods: {

    //w 选择其它编码规则
    pushOrder(order) {
      if (this.open_num_type === '1') {
        this.group_number = order;
      } else {
        this.create_data.in_number = order;
      }
      //this.group_number = order
      this.modalClose01();
    },
    //w 定义 打开对话框时的 函数 //W编号规则弹窗
    showModal01(type) {
      this.open_num_type = type
      console.log('this.Numberrulelist = true')
      this.Numberrulelist = true
    },
    //w 定义 关闭对话框时的 函数 //W编号规则弹窗
    modalClose01() {
      this.Numberrulelist = false
    },

    callback(val) {
      console.log(val);
    },
    //w  end

    befName() {
      if ((typeof this.$refs.bef) === 'undefined') {
        return '';
      } else {
        return this.$refs.bef.create_data.name
      }
    },
    befId() {
      if ((typeof this.$refs.bef) === 'undefined') {
        return 0;
      } else {
        return this.$refs.bef.create_data.id
      }
    },
    mountedDo() {
      this.getType();
      this.getSave();
      this.getUser();
      this.group_id = this.$route.params.id
      this.getInfo();
    },
    getInfo() {
      this.$sa0.post({
        url: this.$api('样品组信息'),
        data: {
          id: this.$route.params.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.name = response.data.info.name;
            this.group_number = response.data.info.group_number;
            this.link_draft = response.data.info.link_draft;
            this.yp_list = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getType() {
      this.$sa0.post({
        url: this.$api('获取样品类型')
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.type_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getSave() {
      this.$sa0.post({
        url: this.$api('获取基础字段全部列表'),
        data: {type: 3}
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.save_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getNumber() {
      this.$sa0.post({
        url: this.$api('请求编号接口'),
        data: {
          num_type: this.num_type
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.get_number = response.data.serialnumber
            this.create_data.in_number = response.data.serialnumber
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getNumber2Group() {
      this.$sa0.post({
        url: this.$api('请求编号接口'),
        data: {
          num_type: this.num_type
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.group_number = response.data.serialnumber
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getUser() {
      this.$sa0.post({
        url: this.$api('获取部门人员分级列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.user_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    addClick() {
      if (!this.create_data.name || this.create_data.name === '') return layer.msg('请输入样品名称')
      if (!this.create_data.in_number || this.create_data.in_number === '') return layer.msg('请输入内部编号')
      if (this.create_data.new_count === 1) {
        let data = {
          types: this.create_data.spec_types,
          name: this.create_data.name,
          children: [this.create_data]
        }
        let k = -1;
        this.yp_list.map((item, key) => {
          if (JSON.stringify(item.types) === JSON.stringify(data.types)
              && item.name === data.name) {
            k = key
          }
        })
        if (k === -1) {
          this.yp_list.push(data)
        } else {
          this.yp_list[k].children = [
            ...this.yp_list[k].children,
            ...data.children
          ]
        }
        this.get_number = '';
        this.visible = false
      } else {
        if (this.create_data.in_number === this.get_number) {
          this.$sa0.post({
            url: this.$api('批量生成默认编号'),
            data: {
              num_type: this.num_type,
              num: this.create_data.new_count - 1
            }
          }).then((response) => {
            this.$sa0.response({
              response: response.data,
              then: (response) => {
                let data = {
                  types: this.create_data.spec_types,
                  name: this.create_data.name,
                  children: [this.create_data]
                }
                response.data.serialnumber.map((item) => {
                  let d = JSON.parse(JSON.stringify(this.create_data));
                  d.in_number = item;
                  data.children.push(d)
                })

                let k = -1;
                this.yp_list.map((item, key) => {
                  if (JSON.stringify(item.types) === JSON.stringify(data.types)
                      && item.name === data.name) {
                    k = key
                  }
                })
                if (k === -1) {
                  this.yp_list.push(data)
                } else {
                  this.yp_list[k].children = [
                    ...this.yp_list[k].children,
                    ...data.children
                  ]
                }
                this.get_number = '';
                this.visible = false
              },
              error: (response) => {
                layer.msg(response.message)
              },
            })
          })
        } else {
          let data = {
            types: this.create_data.spec_types,
            name: this.create_data.name,
            children: [this.create_data]
          }
          let serialnumber = []
          for (let i = 0; i < this.create_data.new_count - 1; i++) {
            serialnumber.push(this.create_data.in_number)
          }
          serialnumber.map((item) => {
            let d = JSON.parse(JSON.stringify(this.create_data));
            d.in_number = item;
            data.children.push(d)
          })
          let k = -1;
          this.yp_list.map((item, key) => {
            if (JSON.stringify(item.types) === JSON.stringify(data.types)
                && item.name === data.name) {
              k = key
            }
          })
          if (k === -1) {
            this.yp_list.push(data)
          } else {
            this.yp_list[k].children = [
              ...this.yp_list[k].children,
              ...data.children
            ]
          }
          this.get_number = '';
          this.visible = false
        }
      }
    },
    openInfo(item) {
      this.create_data = item;
      this.visible = true
    },
    chooseAll() {
      for (let i in this.yp_list) {
        for (let j in this.yp_list[i].children) {
          this.$set(this.yp_list[i].children[j], 'choose', true)
        }
      }
    },
    cancelAll() {
      for (let i in this.yp_list) {
        for (let j in this.yp_list[i].children) {
          this.$set(this.yp_list[i].children[j], 'choose', false)
        }
      }
    },
    removeChoose() {
      for (let i in this.yp_list) {
        if (this.yp_list[i].children.length === 0) {
          console.log(this.yp_list)
          this.yp_list.splice(i, 1)
          return this.removeChoose()
        } else {
          for (let j in this.yp_list[i].children) {
            if (this.yp_list[i].children[j].choose) {
              this.yp_list[i].children.splice(j, 1)
              return this.removeChoose()
            }
          }
        }
      }
    },
    onClose() {
      this.visible = false
    },
    saveClick() {
      if (this.create_data.spec_types.length === 0) return layer.msg('请选择样品类型')
      if (!this.name || this.name === '') return layer.msg('请输入样品组名称')
      if (!this.group_number || this.group_number === '') return layer.msg('请输入样品组编号')
      let yp_arr = [];
      let err = ''
      this.yp_list.map((item) => {
        if (item.types.length !== 0) {
          item.spec_type = item.types[item.types.length - 1]
        } else {
          err  = `第${key}行 样品类型必填`
          item.spec_type = 0
        }
        item.spec_types = JSON.stringify(item.types)
        item.children.map((i) => {
          if (i.pick_pers.length !== 0) {
            i.pick_per = i.pick_pers[i.pick_pers.length - 1]
          } else {
            i.pick_per = 0
          }
          i.pick_pers = JSON.stringify(i.pick_pers)
          yp_arr.push({
            id: i.id,
            spec_type: item.spec_type,
            spec_types: item.spec_types,
            name: item.name,
            in_number: i.in_number,
            pick_space: i.pick_space,
            jing_dot: i.jing_dot,
            wei_dot: i.wei_dot,
            pick_per: i.pick_per,
            pick_pers: i.pick_pers,
            save_id: i.save_id,
          })
        })
      })
      if(err !== '') return layer.msg(err)
      if (yp_arr.length === 0) return layer.msg('请添加样品')
      this.$sa0.post({
        url: this.$api('修改样品组'),
        data: {
          id: this.$route.params.id,
          name: this.name,
          group_number: this.group_number,
          link_draft: this.befId(),
          yp_arr: yp_arr
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('修改成功')
            this.reloadList()
            this.getInfo()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    openBefore() {

      this.$refs.bef.visible = true
      let id = this.link_draft
      if (Number(id) === 0) id = this.$refs.bef.create_data.id
      this.link_draft = id;
      console.log('openBefore', id)
      this.$refs.bef.reloadInfo(id)
    },
  }
}
</script>
<template>
  <div>
    <BeforeC :bid="link_draft" ref="bef"></BeforeC>
    <div class="group_info_wrapper">
      <a-space>
        <div class="info_wrapper">
          <div class="my-3">
            <a-space>
              <div class="input_title_wrapper text-right"><b>样品组名称：</b></div>
              <div>
                <a-input v-model="name" class="input_wrapper" placeholder="请输入样品组名称"></a-input>
              </div>
            </a-space>
          </div>
          <div class="my-3">
            <a-space>
              <div class="input_title_wrapper text-right"><b>样品组编号：</b></div>
              <div>
                <a-input v-model="group_number" class="input_wrapper" placeholder="请输入样品组编号"></a-input>
              </div>
              <i class="czs-angle-left-l" @click="getNumber2Group()"></i>
              <a-button type="primary" size="small" @click="showModal01('1')">选择其他编号规则</a-button>
            </a-space>
          </div>
          <div class="my-3 before_wrapper">
            <div @click="openBefore()" class="before_cover_wrapper"></div>
            <a-space>
              <div class="input_title_wrapper text-right"><b>预委托单信息：</b></div>
              <div>
                <a-input :value="befName()" class="input_wrapper" placeholder="请输入预委托单信息"></a-input>
              </div>
            </a-space>
          </div>
        </div>
        <div class="line_wrapper">
          <div class="code_wrapper"></div>
          <a-button class="w-full mt-3" type="primary" size="small">选择其他样品组条码样式</a-button>
          <a-button class="w-full mt-3" type="primary">打印样品组条码</a-button>
        </div>
      </a-space>
    </div>
    <div class="list_wrapper mt-5">
      <div class="list_button_wrapper">
        <a-space align="end">
          <a-button @click="chooseAll()" type="primary" size="small">全选样品</a-button>
          <a-button @click="cancelAll()" type="primary" size="small">取消选中</a-button>
          <a-button type="primary" size="small">打印选中样品条码</a-button>
          <div class="list_code_wrapper"></div>
          <a-button type="primary" size="small">选择其他样品条码样式</a-button>
          <a-button @click="removeChoose()" type="primary" size="small">作废选中样品</a-button>
          <a-button @click="openInfo({
        id: 0,
        type: 0,
        spec_type: '',
        choose: false,
        spec_types: [],
        name: '',
        in_number: '',
        out_number: '',
        check_item: '',
        check_func: '',
        check_per: '',
        get_per: '',
        pick_per: '',
        pick_time: '',
        create_time: '',
        update_time: '',
        is_del: 2,
        group_id: '',
        check_items: [],
        check_funcs: [],
        check_pers: [],
        pick_pers: [],
        get_pers: [],
        save_id: undefined,
        jing_dot: '',
        wei_dot: '',
        pick_space: '',
        status: 0,
        new_count: 1,
      })" type="primary" size="small">添加新样品
          </a-button>
        </a-space>
      </div>
      <div>
        <table class="layui-table">
          <thead>
          <tr>
            <th>样品类型</th>
            <th>样品名称</th>
            <th>样品编号</th>
            <th>采集地点</th>
            <th>经纬度</th>
            <th>采集时间</th>
            <th>采集人</th>
            <th>样品状态</th>
            <th>样品存放位置</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(item,key) in yp_list">
            <tr v-for="(iitem,ikey) in item.children">
              <td v-if="ikey === 0" :rowspan="item.children.length">
                <a-cascader
                    class="w-full" v-model="item.types"
                    :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                    :options="type_list" placeholder="请选择样品类型"/>
              </td>
              <td v-if="ikey === 0" :rowspan="item.children.length">
                <a-input v-model="item.name" placeholder="请输入样品名称"></a-input>
              </td>
              <td>
                <a-space>
                  <a-checkbox v-model="iitem.choose"></a-checkbox>
                  <a-input v-model="iitem.in_number" placeholder="请输入内部编号"></a-input>
                </a-space>
              </td>
              <td>
                <a-input v-model="iitem.pick_space" placeholder="请输入采集地点"></a-input>
              </td>
              <td>
                <a-space>
                  <a-input v-model="iitem.jing_dot" style="width: 100px;" placeholder="经度"></a-input>
                  <a-input v-model="iitem.wei_dot" style="width: 100px;" placeholder="纬度"></a-input>
                </a-space>
              </td>
              <td>{{ iitem.pick_time ? iitem.pick_time : '无' }}</td>
              <td>
                <a-cascader
                    class="w-full" v-model="iitem.pick_pers"
                    :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                    :options="user_list" placeholder="请选择样品类型"/>
              </td>
              <td>{{ iitem.status ? iitem.status_turn : '新添加' }}</td>
              <td>
                <a-select placeholder="请选择存档地点" class="w-full" v-model="iitem.save_id">
                  <a-select-option v-for="(i,k) in save_list" :key="k" :value="i.id">
                    {{ i.name }}
                  </a-select-option>
                </a-select>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <a-drawer
          title="样品信息"
          placement="bottom"
          :visible="visible"
          @close="onClose"
          height="330"
      >
        <div>
          <table class="layui-table">
            <colgroup>
              <col width="100">
              <col width="70">
              <col width="150">
              <col width="200">
              <col width="150">
              <col width="200">
            </colgroup>
            <tbody>
            <tr>
              <td class="text-red-400">样品类型</td>
              <td>
                <a-cascader
                    class="w-full" v-model="create_data.spec_types"
                    :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                    :options="type_list" placeholder="请选择样品类型"/>
              </td>
              <td>
                <span class="text-red-400">样品名称</span>
              </td>
              <td>
                <a-input v-model="create_data.name" placeholder="请选择样品名称"></a-input>
              </td>
              <td>
                <span class="text-red-400">内部编号</span>
              </td>
              <td>
                <a-space>
                  <a-input v-model="create_data.in_number" placeholder="请选择内部编号"></a-input>
                  <i class="czs-angle-left-l" @click="getNumber()"></i>
                  <a-button type="primary" @click="showModal01('2')">其他编号</a-button>
                </a-space>
              </td>
            </tr>
            <tr>
              <td>经纬度</td>
              <td>
                <a-space>
                  <a-input v-model="create_data.jing_dot" style="width: 100px;" placeholder="经度"></a-input>
                  <a-input v-model="create_data.wei_dot" style="width: 100px;" placeholder="纬度"></a-input>
                </a-space>
              </td>
              <td>采集地点</td>
              <td>
                <a-input v-model="create_data.pick_space" placeholder="请选择采集地点"></a-input>
              </td>
              <td>采集人</td>
              <td>
                <a-cascader
                    class="w-full" v-model="create_data.pick_pers"
                    :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                    :options="user_list" placeholder="请选择样品类型"/>
              </td>
            </tr>
            <tr>
              <td>存放地点</td>
              <td>
                <a-select placeholder="请选择存档地点" class="w-full" v-model="create_data.save_id">
                  <a-select-option v-for="(i,k) in save_list" :key="k" :value="i.id">
                    {{ i.name }}
                  </a-select-option>
                </a-select>
              </td>
              <td>添加数量</td>
              <td>
                <a-input v-model="create_data.new_count"></a-input>
              </td>
              <td colspan="2">
                <span class="text-red-300">数量大于1时将按照设置的样品编号规则自动生成后续后续编号,其余数据和之前的一致</span>
              </td>
            </tr>
            </tbody>
          </table>
          <div>
            <a-space>
              <a-button @click="addClick()" type="primary">保存</a-button>
              <a-button>保存并打印当前条码</a-button>
            </a-space>
          </div>
        </div>
      </a-drawer>
    </div>
    <div>
      <a-button @click="saveClick()" type="primary">保存</a-button>
    </div>

    <!---w 选择其它编码规则 w---->
    <div v-if="Numberrulelist">
      <Numberrulelistopen :callback="pushOrder" :ok="modalClose01" :cancel="modalClose01"
                          :Numberrulelist="Numberrulelist"></Numberrulelistopen>
    </div>

  </div>
</template>
<style scoped>
.before_wrapper {
  position: relative;
}

.before_cover_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.list_code_wrapper {
  width: 200px;
  height: 100px;
  background: #00000040;
}

.code_wrapper {
  width: 300px;
  height: 100px;
  background: #00000040;
}

.line_wrapper {
  margin-left: 50px;
}

.input_title_wrapper {
  width: 180px;
}

.input_wrapper {
  width: 300px;
}
</style>
