<script>
/***
 Name: item
 Code: sa0ChunLuyu
 Time: 2021/11/15 19:32
 Remark: 内容
 */
import Create from './create/create.vue'
import Update from './update/update.vue'

export default {
  props: ['reloadList'],
  components: {Create, Update},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>
<template>
  <div class="item_wrapper">
    <Create v-if="Number($route.params.id) === 0" :reloadList="reloadList"></Create>
    <Update v-if="Number($route.params.id) !== 0" :reloadList="reloadList"></Update>
  </div>
</template>
<style scoped>
.item_wrapper {
  margin-left: 270px;
}
</style>
